import React from 'react'
import DetailTripPrivate from './DetailTripPrivate'

const DetailTrip = () => {
  return (
    <div>
        <DetailTripPrivate/>
    </div>
  )
}

export default DetailTrip